/* eslint-disable no-undef */

document.addEventListener('DOMContentLoaded', () => {
  const animateProgress = {
    init() {
      this.cacheDom();
      this.bindEvents();
    },

    cacheDom() {
      this.$window = $(window);
      this.$progressBar = $('.js-performance-graphs-percentage-bar');
    },

    bindEvents() {
      this.$window.on('scroll.progressAnimation', () => this.animate());
    },

    animate() {
      const visibilityHelper = new Sofatutor.Classes.VisibilityHelper();

      this.$progressBar.each((_i, e) => {
        if (visibilityHelper.isVisible($(e))) {
          $(e)
            .find('.js-performance-graphs-progress')
            .addClass('is-progressed');

          if (
            this.$progressBar.find('.is-progressed').length ===
            $('.js-performance-graphs-progress').length
          ) {
            this.$window.off('scroll.progressAnimation');
          }
        }
      });
    }
  };

  if ($('.performance-graphs__percentage-bar')[0]) {
    animateProgress.init();
  }
});
